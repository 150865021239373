import {
  CompanyUpdateRequestBody,
  CompanyUpdateRequestParams,
  CompanyWhitelistIpCreateRequestBody,
  CreateCompanySdsRequestUnit,
  DeleteCompanySdsesRequestBody,
  UpdateCompanySdsRequestBody,
  CompanyTagCreateRequestBody,
  CompanyTagUpdateRequestBody,
  UpdateCompanySdsesRequestBody,
  UpdateReconfirmationSdsRequestBody,
  GetCompanySdsesRequestBody,
  GetCompanySdsesAsExcelRequestBody,
  GetCompanySdsesCountRequestQuery,
} from '@/interfaces/api/general/company';
import { DynamicListResultRequestBody } from '@/interfaces/api/general/dynamicListResult';
import {
  InquiryRequestBody,
  QuotationRequestBody,
} from '@/interfaces/api/general/info';
import { UserUpdateRequestBody } from '@/interfaces/api/general/user';
import { generalApi } from '@/lib/api/general';
import { NewCompany } from '@/lib/constructor/vuex/company';
import { NewUser } from '@/lib/constructor/vuex/user';
import router from '@/router';
import {
  actionCreateCompanyUser,
  actionDeleteCompanyUser,
  actionGetCompanyUser,
  actionGetCompanyUserCsv,
  actionPostCompanyUserCsv,
  actionUpdateCompanyUser,
} from '@/store/main/actions/actionCompanyUser';
import {
  actionCreateCompanyDepartment,
  actionDeleteCompanyDepartment,
  actionGetCompanyDepartments,
  actionUpdateCompanyDepartment,
} from '@/store/main/actions/actionDepartment';
import {
  actionCreateDynamicList,
  actionGetDynamicList,
  actionGetDynamicListResults,
  actionGetDynamicLists,
  actionGetExportDynamicListResults,
  actionRequestDynamicList,
  actionResumeDynamicLists,
  actionSuspendDynamicLists,
} from '@/store/main/actions/actionDynamicList';
import { actionSignUp } from '@/store/main/actions/actionSignUp';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetCompany,
  commitSetLogInError,
  commitSetLoggedIn,
  commitSetToken,
  commitSetUserLocale,
  commitSetCurrentUser,
  commitSetDepartments,
} from './mutations';
import { AppNotification, MainState } from './state';

export type MainContext = ActionContext<MainState, State>;

export const actionsMain = {
  async actionPostInquiry(
    context: MainContext,
    payload: {
      payload: InquiryRequestBody;
    }
  ) {
    try {
      const response = await generalApi.inquiry(payload.payload);
      if (response.data) {
        commitAddNotification(context, {
          content:
            '入力されたメールアドレスに資料を送信しました。メールボックスをご確認ください。',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },
  async actionPostInquiryEn(
    context: MainContext,
    payload: {
      payload: InquiryRequestBody;
    }
  ) {
    try {
      const response = await generalApi.inquiryEn(payload.payload);
      if (response.data) {
        commitAddNotification(context, {
          content:
            'Thank you for your inquiry, we will reply within 2-3 business days.',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },
  async actionPostQuoation(
    context: MainContext,
    payload: {
      payload: QuotationRequestBody;
    }
  ) {
    try {
      const response = await generalApi.quotation(payload.payload);
      if (response.data) {
        commitAddNotification(context, {
          content: 'お見積頂きありがとうございます。',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },
  actionSignUp,
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const response = await generalApi.logInGetToken(
        payload.username,
        payload.password
      );
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetCurrentUser(context);
        await dispatchGetCompany(context, {
          companyId: context.state.userProfile?.company_id ?? '',
        });
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, {
          content: 'ログインに成功しました。引き続きケミカンをご利用ください。',
          color: 'success',
        });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      const axiosError = err as AxiosError<any>;
      commitAddNotification(context, {
        content: axiosError?.response?.data?.detail,
        color: 'error',
      });
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetCompany(context: MainContext, payload: { companyId: string }) {
    try {
      const response = await generalApi.getCompany(
        context.state.token,
        payload.companyId
      );
      if (response.data) {
        commitSetCompany(context, {
          uploaded_file_number: context.state.company.uploaded_file_number,
          name: response.data.name,
          uuid: response.data.uuid,
          phone: response.data.phone,
          plan_id: response.data.plan_id,
          customer_id: response.data.customer_id,
          subscription_id: response.data.subscription_id,
          next_paid_at: response.data.next_paid_at,
          subscribed_at: response.data.subscribed_at,
          renewed_at: response.data.renewed_at,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompany(
    context: MainContext,
    payload: {
      params: CompanyUpdateRequestParams;
      payload: CompanyUpdateRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateCompany(
        context.state.token,
        payload.params,
        payload.payload
      );
      if (response.data) {
        commitAddNotification(context, {
          content: '企業情報の更新が完了しました',
          color: 'success',
        });
        commitSetCompany(context, {
          uploaded_file_number: context.state.company.uploaded_file_number,
          name: response.data.name,
          uuid: response.data.uuid,
          phone: response.data.phone,
          plan_id: response.data.plan_id,
          customer_id: response.data.customer_id,
          subscription_id: response.data.subscription_id,
          next_paid_at: response.data.next_paid_at,
          subscribed_at: response.data.subscribed_at,
          renewed_at: response.data.renewed_at,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionDeleteCompany(
    context: MainContext,
    payload: { companyId: string }
  ) {
    try {
      const response = await generalApi.deleteCompany(
        context.state.token,
        payload.companyId
      );
      if (response.data) {
        await dispatchLogOut(context);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  actionGetCompanyDepartments,
  actionCreateCompanyDepartment,
  actionUpdateCompanyDepartment,
  actionDeleteCompanyDepartment,

  actionGetCompanyUser,
  actionCreateCompanyUser,
  actionUpdateCompanyUser,
  actionDeleteCompanyUser,
  actionGetCompanyUserCsv,
  actionPostCompanyUserCsv,

  async actionGetCurrentUser(context: MainContext) {
    try {
      const response = await generalApi.getMe(context.state.token);
      if (response.data) {
        commitSetCurrentUser(context, {
          uuid: response.data.uuid,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          middle_name: response.data.middle_name,
          email: response.data.email,
          phone: response.data.phone,
          departments: response.data.departments,
          position: response.data.position,
          company_id: response.data.company_id,
          is_active: response.data.is_active,
          is_subscribe: response.data.is_subscribe,
          role_id: response.data.role_id,
          role: response.data.role,
          created_at: response.data.created_at,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionUpdateCurrentUser(
    context: MainContext,
    payload: UserUpdateRequestBody
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          generalApi.updateMe(context.state.token, payload),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ])
      )[0];
      commitSetCurrentUser(context, {
        uuid: response.data.uuid,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        middle_name: response.data.middle_name,
        email: response.data.email,
        phone: response.data.phone,
        departments: response.data.departments,
        position: response.data.position,
        company_id: response.data.company_id,
        is_active: response.data.is_active,
        is_subscribe: response.data.is_subscribe,
        role_id: response.data.role_id,
        role: response.data.role,
        created_at: response.data.created_at,
      });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'ユーザー情報を更新しました。',
        color: 'success',
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await generalApi.getMe(token);
          await commitSetLoggedIn(context, true);
          await commitSetCurrentUser(context, {
            uuid: response.data.uuid,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            middle_name: response.data.middle_name,
            email: response.data.email,
            phone: response.data.phone,
            departments: response.data.departments,
            position: response.data.position,
            company_id: response.data.company_id,
            is_active: response.data.is_active,
            is_subscribe: response.data.is_subscribe,
            role_id: response.data.role_id,
            role: response.data.role,
            created_at: response.data.created_at,
          });
          await dispatchGetCompany(context, {
            companyId: context.state.userProfile?.company_id ?? '',
          });
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, '');
    commitSetLoggedIn(context, false);
    commitSetCurrentUser(context, { ...NewUser });
    commitSetCompany(context, { ...NewCompany });
    commitSetDepartments(context, []);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, {
      content: 'ログアウトしました',
      color: 'success',
    });
  },
  actionRouteLogOut() {
    router.push({ name: 'login' });
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError<any>) {
    if (payload.response?.status === 401) {
      commitAddNotification(context, {
        content: 'トークンの有効期限が切れました再度ログインしてください。',
        color: 'error',
      });
      await dispatchLogOut(context);
    } else if (payload.response?.status === 403) {
      commitAddNotification(context, {
        content: '権限がありません。' + payload.response?.data.detail,
        color: 'error',
      });
    } else if (payload.response?.status === 400) {
      let errorMessage = '';

      if (payload.config?.method === 'get') {
        errorMessage = 'データ取得エラーです。';
      } else {
        errorMessage = '入力内容に誤りがあります。';
      }

      commitAddNotification(context, {
        content: errorMessage + payload.response?.data.detail,
        color: 'error',
      });
    } else if (payload.response?.status === 404) {
      commitAddNotification(context, {
        content:
          'リソースが見つかりませんでした。' + payload.response?.data.detail,
        color: 'error',
      });
    } else if (payload.response?.status === 415) {
      commitAddNotification(context, {
        content: 'ファイル形式が不正です。' + payload.response?.data.detail,
        color: 'error',
      });
    } else if (payload.response?.status === 500) {
      commitAddNotification(context, {
        content: 'サーバーエラーです。' + payload.response?.data.detail,
        color: 'error',
      });
    } else {
      commitAddNotification(context, {
        content: '不明なエラーです。' + payload.response?.data.detail,
        color: 'error',
      });
    }
  },
  actionRouteLoggedIn() {
    if (
      router.currentRoute.path === '/login' ||
      router.currentRoute.path === '/signup'
    ) {
      const redirect = router.currentRoute.query.redirect;
      if (typeof redirect === 'string' && redirect.startsWith('/')) {
        router.push({ path: redirect });
      } else {
        router.push({ name: 'sdsUpload' });
      }
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: '入力されたメールアドレスをチェックしています。',
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification);
      await generalApi.passwordRecovery(payload.username);
      commitRemoveNotification(context, loadingNotification);

      commitAddNotification(context, {
        content:
          '入力されたメールアドレスにパスワード再発行のメールを送信しました。',
        color: 'success',
      });

      await dispatchLogOut(context);
    } catch (error) {
      const axiosError = error as AxiosError;
      commitAddNotification(context, {
        color: 'error',
        content: 'メールアドレスが正しくありません。',
      });
      throw axiosError;
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string }
  ) {
    const loadingNotification = {
      content: 'パスワードをリセットしています',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        generalApi.resetPassword(payload.password, payload.token),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'パスワードのリセット処理が完了しました',
        color: 'success',
      });
      await dispatchLogOut(context);
      router.push({ name: 'login' });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content:
          'パスワードのリセット処理中にエラーが発生しました。時間をおいて再度リセットをお願いいたします。',
      });
    }
  },
  async actionActivateUser(context: MainContext, payload: { token: string }) {
    const notificationActivating = {
      content: 'アカウント登録完了しています。',
      showProgress: true,
    };
    const notificationActivated = {
      content: 'アカウント登録完了が完了しました。ログインをお願いいたします。',
      showProgress: false,
    };
    try {
      commitAddNotification(context, notificationActivating);
      await generalApi.activateUser(payload);
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, notificationActivated);
    } catch (error) {
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, {
        color: 'error',
        content:
          '処理中にエラーが発生しました。運営へご連絡をお願いいたします。',
      });
    }
  },
  async actionRegenerateActivationMail(
    context: MainContext,
    payload: { email: string }
  ) {
    const notificationActivating = {
      content: 'アカウント登録完了メールを送信しています。',
      showProgress: true,
    };
    const notificationActivated = {
      content:
        'アカウント登録完了メールの送信が完了しました。24時間以内にアカウントをアカウント登録完了をしてください。',
      showProgress: false,
    };
    try {
      commitAddNotification(context, notificationActivating);
      await generalApi.regenerateActivationMail({ email: payload.email });
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, notificationActivated);
    } catch (error) {
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, {
        color: 'error',
        content:
          '処理中にエラーが発生しました。運営へご連絡をお願いいたします。',
      });
    }
  },

  async actionGetCompanyWhitelistIp(
    context: MainContext,
    payload: {
      companyId: string;
    }
  ) {
    try {
      return await generalApi.getCompanyWhitelistIp(
        context.state.token,
        payload.companyId
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetClientIp(context: MainContext) {
    try {
      return await generalApi.getClientIp(context.state.token);
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },

  async actionCreateCompanyWhitelistIp(
    context: MainContext,
    payload: {
      payload: CompanyWhitelistIpCreateRequestBody;
      companyId: string;
    }
  ) {
    try {
      const response = await generalApi.createCompanyWhitelistIp(
        context.state.token,
        payload.companyId,
        payload.payload
      );
      if (response) {
        commitAddNotification(context, {
          content: 'IPアドレスを追加しました',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteCompanyWhitelistIp(
    context: MainContext,
    payload: {
      companyId: string;
      ipAddress: string;
    }
  ) {
    try {
      const response = await generalApi.deleteCompanyWhitelistIp(
        context.state.token,
        payload.companyId,
        payload.ipAddress
      );
      if (response) {
        commitAddNotification(context, {
          content: 'IPアドレスを削除しました',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySds(
    context: MainContext,
    payload: {
      companyId: string;
      sdsId: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanySds(
        context.state.token,
        payload.companyId,
        payload.sdsId
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanyUploadUsers(
    context: MainContext,
    payload: {
      companyId: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanyUploadUsers(
        context.state.token,
        payload.companyId
      );
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySdses(
    context: MainContext,
    payload: {
      companyId;
      data: GetCompanySdsesRequestBody;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdses(
        context.state.token,
        payload.companyId,
        payload.data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySdsesAsExcel(
    context: MainContext,
    payload: {
      companyId: string;
      data: GetCompanySdsesAsExcelRequestBody;
    }
  ) {
    if (payload.data.export_type === 'csv') {
      if (!payload.data.export_format) {
        throw new Error('エクスポート単位が設定されていません');
      }
    }

    try {
      const response = await generalApi.getCompanySdsesExport(
        context.state.token,
        payload.companyId,
        payload.data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response?.status !== 400) {
        await dispatchCheckApiError(context, axiosError);
      } else {
        const { detail, denied_sds_list: deniedSdsList } =
          axiosError.response.data;
        commitAddNotification(context, {
          content: `以下の${detail}\n${deniedSdsList
            .map((s: { name: string }) => s.name)
            .join(', ')}`,
          color: 'error',
        });
      }
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionGetCompanySdsesCount(
    context: MainContext,
    payload: {
      companyId: string;
      data: GetCompanySdsesCountRequestQuery;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdsesCount(
        context.state.token,
        payload.companyId,
        payload.data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySdsPdf(
    context: MainContext,
    payload: {
      companyId: string;
      fileUuid: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdsPdf(
        context.state.token,
        payload.companyId,
        payload.fileUuid
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionGetGcsSignedUrl(
    context: MainContext,
    payload: {
      companyId: string;
      sdsList: Array<CreateCompanySdsRequestUnit>;
    }
  ) {
    try {
      const response = await generalApi.getGcsSignedUrl(
        context.state.token,
        payload.companyId,
        payload.sdsList
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionCreateCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      departmentIdList: string[];
      sdsList: Array<CreateCompanySdsRequestUnit>;
    }
  ) {
    try {
      const { companyId, departmentIdList, sdsList } = payload;
      const response = await generalApi.createSdses(
        context.state.token,
        companyId,
        {
          department_id_list: departmentIdList,
          sds_list: sdsList,
        }
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        content:
          'アップロードに失敗しました。アップロードの上限数が契約プラン上限数を超えていないかご確認ください。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      data: UpdateCompanySdsesRequestBody;
    }
  ) {
    try {
      const { companyId, data } = payload;
      const response = await generalApi.updateCompanySdses(
        context.state.token,
        companyId,
        data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      data: DeleteCompanySdsesRequestBody;
    }
  ) {
    try {
      const { companyId, data } = payload;
      const response = await generalApi.deleteCompanySdses(
        context.state.token,
        companyId,
        data
      );
      commitAddNotification(context, {
        content: '選択したファイルを削除しました。',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response?.status !== 400) {
        await dispatchCheckApiError(context, axiosError);
      } else {
        const { detail, denied_sds_list: deniedSdsList } =
          axiosError.response.data;
        commitAddNotification(context, {
          content: `以下の${detail}\n${deniedSdsList
            .map((s: { name: string }) => s.name)
            .join(', ')}`,
          color: 'error',
        });
      }
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompanySds(
    context: MainContext,
    payload: {
      companyId: string;
      fileId: string;
      form: UpdateCompanySdsRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateCompanySds(
        context.state.token,
        payload.companyId,
        payload.fileId,
        payload.form
      );
      commitAddNotification(context, {
        content: '更新しました',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        content:
          '処理に失敗しました。ページを更新して、再度実行をお願いいたします。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  actionGetDynamicList,
  actionGetDynamicLists,
  actionGetDynamicListResults,
  actionGetExportDynamicListResults,
  actionCreateDynamicList,
  actionRequestDynamicList,
  actionResumeDynamicLists,
  actionSuspendDynamicLists,

  async actionUpdateReconfirmationSds(
    context: MainContext,
    payload: {
      companyId: string;
      sdsId: string;
      form: UpdateReconfirmationSdsRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateReconfirmationSds(
        context.state.token,
        payload.companyId,
        payload.sdsId,
        payload.form
      );
      commitAddNotification(context, {
        content: '更新しました',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        content:
          '処理に失敗しました。ページを更新して、再度実行をお願いいたします。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetExportCreateSimple(
    context: MainContext,
    payload: {
      sdsId: string;
    }
  ) {
    try {
      const response = await generalApi.getExportCreateSimple(
        context.state.token,
        payload.sdsId
      );
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetExportCreateSimpleV3(
    context: MainContext,
    payload: {
      sdsId: string;
    }
  ) {
    try {
      const response = await generalApi.getExportCreateSimpleV3(
        context.state.token,
        payload.sdsId
      );
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionCancelExportCreateSimple(context: MainContext) {
    try {
      const response = await generalApi.cancelCreateSimpleExportRequest();
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
    }
  },

  async actionCancelExportCreateSimpleV3(context: MainContext) {
    try {
      const response = await generalApi.cancelCreateSimpleV3ExportRequest();
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
    }
  },

  async actionCreateReanalysisSds(
    context: MainContext,
    payload: {
      sdsId: string;
      comment: string;
    }
  ) {
    try {
      const response = await generalApi.createReanalysisSds(
        context.state.token,
        payload
      );

      commitAddNotification(context, {
        content: '再データ化依頼を行いました',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
    }
  },

  async actionPutDynamicListResult(
    context: MainContext,
    payload: {
      sdsId: string;
      dynamicListId: number;
      dynamicListResult: DynamicListResultRequestBody;
    }
  ) {
    try {
      const response = await generalApi.putDynamicListResult(
        context.state.token,
        payload.sdsId,
        payload.dynamicListId,
        payload.dynamicListResult
      );
      commitAddNotification(context, {
        content: 'チェックリストを更新しました',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        content:
          '処理に失敗しました。ページを更新して、再度実行をお願いいたします。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionPostDynamicListResult(
    context: MainContext,
    payload: {
      sdsId: string;
      dynamicListId: number;
    }
  ) {
    try {
      const response = await generalApi.postDynamicListResult(
        context.state.token,
        payload.sdsId,
        payload.dynamicListId
      );
      commitAddNotification(context, {
        content: 'チェックリストに追加しました',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        content:
          '処理に失敗しました。ページを更新して、再度実行をお願いいたします。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteDynamicListResult(
    context: MainContext,
    payload: {
      sdsId: string;
      dynamicListId: number;
    }
  ) {
    try {
      const response = await generalApi.deleteDynamicListResult(
        context.state.token,
        payload.sdsId,
        payload.dynamicListId
      );
      commitAddNotification(context, {
        content: 'チェックリストから削除しました',
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        content:
          '処理に失敗しました。ページを更新して、再度実行をお願いいたします。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  actionSetUserLocale(context: MainContext, locale: string) {
    console.log(locale);
    commitSetUserLocale(context, locale);
  },

  async actionCreateCompanyTag(
    context: MainContext,
    payload: {
      payload: CompanyTagCreateRequestBody;
      companyId: string;
    }
  ) {
    try {
      const response = await generalApi.createCompanyTag(
        context.state.token,
        payload.companyId,
        payload.payload
      );
      if (response) {
        commitAddNotification(context, {
          content: 'タグが追加されました。',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      commitAddNotification(context, {
        content: 'タグは既に存在します。',
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetGompanyTag(
    context: MainContext,
    payload: {
      companyId: string;
    }
  ) {
    try {
      return await generalApi.getCompanyTag(
        context.state.token,
        payload.companyId
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteCompanyTag(
    context: MainContext,
    payload: {
      id: number;
    }
  ) {
    try {
      const response = await generalApi.deleteCompanyTag(
        context.state.token,
        payload.id
      );
      if (response) {
        commitAddNotification(context, {
          content: 'タグが削除されました',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompanyTag(
    context: MainContext,
    payload: {
      payload: CompanyTagUpdateRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateCompanyTag(
        context.state.token,
        payload.payload
      );
      if (response) {
        commitAddNotification(context, {
          content: '更新に成功',
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchPostInquiry = dispatch(actionsMain.actionPostInquiry);
export const dispatchPostInquiryEn = dispatch(actionsMain.actionPostInquiryEn);
export const dispatchPostQuotation = dispatch(actionsMain.actionPostQuoation);
export const dispatchCheckApiError = dispatch(actionsMain.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actionsMain.actionCheckLoggedIn);
export const dispatchGetCompany = dispatch(actionsMain.actionGetCompany);
export const dispatchGetCompanyDepartments = dispatch(
  actionsMain.actionGetCompanyDepartments
);
export const dispatchCreateCompanyDepartment = dispatch(
  actionsMain.actionCreateCompanyDepartment
);
export const dispatchUpdateCompanyDepartment = dispatch(
  actionsMain.actionUpdateCompanyDepartment
);
export const dispatchDeleteCompanyDepartment = dispatch(
  actionsMain.actionDeleteCompanyDepartment
);
export const dispatchUpdateCompany = dispatch(actionsMain.actionUpdateCompany);
export const dispatchDeleteCompany = dispatch(actionsMain.actionDeleteCompany);
export const dispatchGetCurrentUser = dispatch(
  actionsMain.actionGetCurrentUser
);
export const dispatchSignUp = dispatch(actionsMain.actionSignUp);
export const dispatchLogIn = dispatch(actionsMain.actionLogIn);
export const dispatchLogOut = dispatch(actionsMain.actionLogOut);
export const dispatchRegenerateActivationMail = dispatch(
  actionsMain.actionRegenerateActivationMail
);
export const dispatchUserLogOut = dispatch(actionsMain.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actionsMain.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actionsMain.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actionsMain.actionRouteLogOut);
export const dispatchUpdateCurrentUser = dispatch(
  actionsMain.actionUpdateCurrentUser
);
export const dispatchUpdateCompanyUser = dispatch(
  actionsMain.actionUpdateCompanyUser
);
export const dispatchRemoveNotification = dispatch(
  actionsMain.removeNotification
);
export const dispatchPasswordRecovery = dispatch(actionsMain.passwordRecovery);
export const dispatchResetPassword = dispatch(actionsMain.resetPassword);
export const dispatchActivateUser = dispatch(actionsMain.actionActivateUser);
export const dispatchGetCompanyUser = dispatch(
  actionsMain.actionGetCompanyUser
);
export const dispatchCreateCompanyUser = dispatch(
  actionsMain.actionCreateCompanyUser
);
export const dispatchDeleteCompanyUser = dispatch(
  actionsMain.actionDeleteCompanyUser
);
export const dispatchGetCompanyUserCsv = dispatch(
  actionsMain.actionGetCompanyUserCsv
);
export const dispatchPostCompanyUserCsv = dispatch(
  actionsMain.actionPostCompanyUserCsv
);
export const dispatchGetCompanySds = dispatch(actionsMain.actionGetCompanySds);
export const dispatchGetCompanyUploadUsers = dispatch(
  actionsMain.actionGetCompanyUploadUsers
);
export const dispatchGetCompanySdses = dispatch(
  actionsMain.actionGetCompanySdses
);
export const dispatchGetCompanySdsesAsExcel = dispatch(
  actionsMain.actionGetCompanySdsesAsExcel
);
export const dispatchGetCompanySdsesCount = dispatch(
  actionsMain.actionGetCompanySdsesCount
);
export const dispatchGetCompanySdsPdf = dispatch(
  actionsMain.actionGetCompanySdsPdf
);
export const dispatchCreateCompanySdses = dispatch(
  actionsMain.actionCreateCompanySdses
);
export const dispatchDeleteCompanySdses = dispatch(
  actionsMain.actionDeleteCompanySdses
);

export const dispatchUpdateCompanySds = dispatch(
  actionsMain.actionUpdateCompanySds
);
export const dispatchUpdateCompanySdses = dispatch(
  actionsMain.actionUpdateCompanySdses
);
export const dispatchUpdateReconfirmationSds = dispatch(
  actionsMain.actionUpdateReconfirmationSds
);
export const dispatchGetCompanyWhitelistIp = dispatch(
  actionsMain.actionGetCompanyWhitelistIp
);
export const dispatchCreateCompanyWhitelistIp = dispatch(
  actionsMain.actionCreateCompanyWhitelistIp
);
export const dispatchDeleteCompanyWhitelistIp = dispatch(
  actionsMain.actionDeleteCompanyWhitelistIp
);
export const dispatchGetGcsSignedUrl = dispatch(
  actionsMain.actionGetGcsSignedUrl
);
export const dispatchGetClientIp = dispatch(actionsMain.actionGetClientIp);
export const dispatchGetDynamicList = dispatch(
  actionsMain.actionGetDynamicList
);
export const dispatchGetDynamicLists = dispatch(
  actionsMain.actionGetDynamicLists
);
export const dispatchResumeDynamicLists = dispatch(
  actionsMain.actionResumeDynamicLists
);
export const dispatchSuspendDynamicLists = dispatch(
  actionsMain.actionSuspendDynamicLists
);
export const dispatchGetDynamicListResults = dispatch(
  actionsMain.actionGetDynamicListResults
);
export const dispatchGetExportDynamicListResults = dispatch(
  actionsMain.actionGetExportDynamicListResults
);
export const dispatchCreateDynamicList = dispatch(
  actionsMain.actionCreateDynamicList
);
export const dispatchRequestDynamicList = dispatch(
  actionsMain.actionRequestDynamicList
);
export const dispatchGetExportCreateSimple = dispatch(
  actionsMain.actionGetExportCreateSimple
);
export const dispatchGetExportCreateSimpleV3 = dispatch(
  actionsMain.actionGetExportCreateSimpleV3
);
export const dispatchCancelCreateSimpleExportRequest = dispatch(
  actionsMain.actionCancelExportCreateSimple
);
export const dispatchCancelCreateSimpleV3ExportRequest = dispatch(
  actionsMain.actionCancelExportCreateSimpleV3
);
export const dispatchCreateReanalysisSds = dispatch(
  actionsMain.actionCreateReanalysisSds
);
export const dispatchPutDynamicListResult = dispatch(
  actionsMain.actionPutDynamicListResult
);
export const dispatchPostDynamicListResult = dispatch(
  actionsMain.actionPostDynamicListResult
);
export const dispatchDeleteDynamicListResult = dispatch(
  actionsMain.actionDeleteDynamicListResult
);
export const dispatchSetUserLocale = dispatch(actionsMain.actionSetUserLocale);

export const dispatchCreateCompanyTag = dispatch(
  actionsMain.actionCreateCompanyTag
);

export const dispatchGetCompanyTag = dispatch(actionsMain.actionGetGompanyTag);

export const dispatchDeleteCompanyTag = dispatch(
  actionsMain.actionDeleteCompanyTag
);

export const dispatchUpdateCompanyTag = dispatch(
  actionsMain.actionUpdateCompanyTag
);
